import dataservice from "../../core/services/dataservice";
import _ from "lodash";
import { set } from "firebase/database";

export const useEmployeesStore = defineStore("employees", {
  state: () => {
    return {
      employees: [],
    };
  },
  getters: {},
  actions: {
    async main() {
      await this.fetch();
    },
    async insert(employee: any) {
      let response = await dataservice.employees.post(employee);
      this.syncToFirebase();
    },
    async update(employee: string) {
      let response = await dataservice.employees.post(employee);
      this.syncToFirebase();
    },
    async delete(employee: any) {
      let response = await dataservice.employees.delete(employee);
      this.syncToFirebase();
    },
    async fetch() {
      let response = await dataservice.employees.getAll();
      this.employees = response.data;

      let newEmp = this.employees.map((emp) => {
        return {
          pages_permissions: {
            all: true,
            reports: false,
            settings: false,
            pos: false,
            history: false,
            menu: false,
            inventory: false,
            logs: false,
            users: false,
            dashboard: false,
          },
          pos_actions: {
            all: true,
            move: false,
            discount: false,
            payment: false,
            void_bill: false,
            void_items: false,
            set_price: false,
          },

          //override
          ...emp,
        };
      });

      this.employees = newEmp;

      //list of employees is zero
      if (this.employees.length == 0) {
        this.employees = [
          {
            employeeId: "admin",
            name: "admin",
            pin: "1234",
            pages_permissions: {
              all: true,
              reports: false,
              settings: false,
              pos: false,
              history: false,
              menu: false,
              inventory: false,
              logs: false,
              users: false,
              dashboard: false,
            },
            pos_actions: {
              all: true,
              move: false,
              discount: false,
              payment: false,
              void_bill: false,
              void_items: false,
              set_price: false,
            },
          },
        ];
      }

      // this.syncToFirebase()

      return response.data;
    },
    syncToFirebase() {
      let data = this.employees;
      let refs = useFirebase().employeesRef();

      let paylaod = _.keyBy(data, "pin");

      set(refs, paylaod);
    },
  },
});
